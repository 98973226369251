<template>
  <div class="extra-service">
    <BackButton
      label="Retour aux services complémentaires"
      @click="onBackBtnClick"
    />
    <ResidentHeader
      page-title="Services complémentaires"
      class="mt-sm-0"
    />
    <div class="page">
      <div class="left">
        <HCard
          class="service-card"
        >
          <div class="banner">
            <div class="splash">
              <img class="background" :src="service.bannerUrl" border="0" />
            </div>
            <div class="avatar">
              <span class="border"></span>
              <img class="photo" :src="service.photoUrl" border="0" />
            </div>
          </div>
          <div class="content">
            <div class="service-title">{{ service.title }}</div>
            <div class="description">
              <MarkdownContent :value="service.description" v-if='service.description' />
            </div>
            <div class="informations">
              <div
                class="information"
                v-for="(info, infoIndex) in service.informations"
                :key="infoIndex"
              >
                <img
                  v-if="info.linkType == 'phone'"
                  class="icon"
                  src="@/assets/images/ehpad/phone.svg"
                  border="0"
                />
                <img
                  v-if="info.linkType == 'email'"
                  class="icon"
                  src="@/assets/images/ehpad/arobase.svg"
                  border="0"
                />
                <div class="text">
                  <a :href="info.linkUrl" target="_blank">{{ info.linkText }}</a>
                </div>
              </div>
            </div>
          </div>
        </HCard>
      </div>
      <div class="right">
        <HCard class="contact-card">
          <div
            v-show="service.ctaType == 'typeform'"
            class="contact-typeform"
            ref="typeform"
          ></div>
          <div
            v-show="service.ctaType == 'link'"
            class="contact-link"
          >
            <div class="link-header">
              Contacter le partenaire
            </div>
            <HBtn
              class="link-btn"
              data-cy="link-btn"
              :href="service.ctaLink"
              target="_blank"
              >{{ service.ctaText }}</HBtn>
          </div>
        </HCard>
      </div>
    </div>

  </div>
</template>

<script>
import MarkdownContent from '@/components/MarkdownContent.vue'
import {
  createWidget
} from '@typeform/embed'
import '@typeform/embed/build/css/widget.css'

import residentMixin from '@/mixins/residentMixin'
import currentUserMixin from '@/mixins/currentUserMixin'
import ToastMixin from '@/mixins/toasts'

import Routes from '@/constants/routes'

import Http from '@/http'

import {
  HBtn,
  HCard
} from '@happytal/bo-ui-library'

import ResidentHeader from '@/views/residents/components/ResidentHeader.vue'
import BackButton from '@/components/BackButton.vue'

export default {
  name: 'ExtraService',
  mixins: [
    ToastMixin,
    residentMixin,
    currentUserMixin
  ],
  props: [
    'service_id'
  ],
  components: {
    HBtn,
    HCard,
    ResidentHeader,
    BackButton,
    MarkdownContent
  },
  data () {
    return {
      service: {}
    }
  },
  methods: {
    onBackBtnClick (e) {
      this.$router.push({
        name: Routes.ExtraServices
      })
    },
    updateService (service) {
      let finalService = {
        id: service.id,
        title: service.title,
        photoUrl: _.get(service, 'partners.logo.url', ''),
        bannerUrl: _.get(service, 'banner.url', ''),
        description: _.get(service, 'long_description', ''),
        informations: []
      }

      if (service.title && service?.partners?.name) {
        document.title = service.partners.name + ' - ' + service.title
      }

      const phone = _.get(service, 'partners.phone', null)
      if (phone) {
        finalService.informations.push({
          linkType: 'phone',
          linkUrl: `tel:${phone}`,
          linkText: phone
        })
      }
      const email = _.get(service, 'partners.email', null)
      if (email) {
        finalService.informations.push({
          linkType: 'email',
          linkUrl: `mailto:${email}`,
          linkText: email
        })
      }
      const formUrl = _.get(service, 'typeform_link', null)
      finalService.ctaType = formUrl ? 'typeform' : 'link'
      switch (finalService.ctaType) {
        case 'typeform':
          const matches = formUrl.match(/\/([^/.]+)$/)
          const formId = _.get(matches, '[1]', '')
          createWidget(formId, {
            container: this.$refs.typeform,
            hidden: {
              beneficiary_first_name: this.$_resident?.firstName || '',
              beneficiary_last_name: this.$_resident?.lastName || '',
              contact_first_name : this.$_currentUser?.firstName || '',
              contact_last_name : this.$_currentUser?.lastName || '',
              contact_email : this.$_currentUser?.email || ''
            }
          })
          break
        case 'link':
          finalService.ctaText = _.get(service, 'cta_name', null)
          finalService.ctaText = finalService.ctaText || `Accéder au site internet`
          finalService.ctaLink = _.get(service, 'cta_link', null)
          break
      }
      this.service = finalService
    }
  },
  mounted () {
    Http.apis.strapi.get(`/extra-services/${this.service_id}`)
    .then((response) => {
      const data = _.get(response, 'data', null)
      //console.log('data', data)
      this.updateService(data)
    })
    .catch((error) => {
      this.$_showErrorToast(`Une erreur s'est produite (${error}).`)
    })
  }
}
</script>

<style lang="scss" scoped>
@import '~@happytal/bo-ui-library/src/styles/variables';
$default-col-width: 480px;

.extra-service {

  .page {
    display: flex;
    flex-wrap: wrap;

    .left {
      width: calc(65% - 42px);

      @media (max-width: map-get($breakpoints, sm)) {
          width: 100%;
      }

      .service-card {
        min-height: 572px;
        padding: 0;

        .banner {
          position: relative;
          background: #EFEFEF;
          border-radius: 6px 6px 0px 0px;

          .splash {
            height: 300px;
            overflow: hidden;
            border-radius: 6px 6px 0px 0px;

            .background {
              width: 100%;
              border-radius: 6px 6px 0px 0px;
            }
          }
          .avatar {
            position: absolute;
            left: 24px;
            bottom: -60px;
            width: 116px;
            height: 116px;
            background: white;
            border: 2px solid #C4C4C4;
            border-radius: 60px;
            text-align: center;
            overflow: hidden;

            .border {
              display: inline-block;
              height: 100%;
              vertical-align: middle;
            }
            .photo {
              vertical-align: middle;
              max-height: 100px;
              max-width: 100px;
            }
          }
        }
        .content {
          padding: 0px 0px 53px 0px;

          .service-title {
            margin: 13px 0px 40px 156px;
            font-style: normal;
            font-weight: 600;
            font-size: 36px;
            line-height: 40px;
            color: black;

            @media (max-width: map-get($breakpoints, sm)) {
              font-size: 20px;
              line-height: none;
            }
          }
          .description {
            padding: 0px 40px 0px 40px;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 29px;
            text-align: justify;
            color: black;
          }
          .informations {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            margin: 0px 40px 0px 40px;

            .information {
              display: flex;
              align-items: center;
              margin: 25px 47px 0px 0px;

              .icon {
                width: 25px;
                height: 25px;
              }
              .text {
                margin: 0px 0px 0px 22px;
              }
            }
          }
        }
      }
    }
    .right {
      width: 35%;
      min-height: 567px;
      margin: 0px 0px 0px 42px;

      @media (max-width: map-get($breakpoints, sm)) {
          width: 100%;
          margin: 30px 0px 0px 0px;
      }

      .contact-card {
        padding: 10px 10px 10px 10px;

        .contact-typeform {
          width: 100%;
          height: 507px;
          border: none;
        }
        .contact-link {
          text-align: center;

          .link-header {
            margin: 20px 0px 0px 0px;
            font-style: normal;
            font-weight: 600;
            font-size: 28px;
            line-height: 29px;
            color: rgba(var(--vs-primary), 1);
          }

          .link-btn {
            margin: 40px 0px 40px 0px;
          }
        }
      }
    }
  }
}
</style>
